<template>
    <div class="custom-user-center">
        <!-- 颜色选择器 -->
        <!-- <div>选择颜色</div> -->
        <!-- <input type="color" value="#2D9900"/> -->

        <div class="uc-con" v-if="list">
            <div class="uc-phone">
                <div class="uc-phone-title" @click="active='1'" :style="{background:list.bannerBg,color:list.bannerFontColor}">{{list.title}}</div>
                <div class="uc-phone-header" @click="active='1'" :style="{background:list.bannerBg,color:list.bannerFontColor}">
                    <div class="uc-user">
                        <img class="avatar" :src="$util.getStaticSrc('head.png')"/>
                        <div>
                            <div class="phone">152****8888</div>
                            <div class="nikename">用户昵称</div>
                        </div>
                    </div>
                    
                    <div class="uc-set">
                        <span class="ri-settings-5-fill"  @click="show=true"></span>
                        <span class="ri-notification-3-line"></span>
                    </div>
                    
                    <div class="uc-nums">
                        <div class="uc-nums-item">
                            <div class="main">￥<span>0.00</span></div>
                            <div class="desc">余额</div>
                        </div>
                        <div class="uc-nums-item">
                            <div class="main">￥<span>0.00</span></div>
                            <div class="desc">冻结</div>
                        </div>
                        <div class="uc-nums-item">
                            <div class="main"><span>0</span></div>
                            <div class="desc">积分</div>
                        </div>
                    </div>
                </div>

                <!-- 会员等级 -->
                <div class="uc-level" @click="active='1'" :style="{background:list.bannerBg}">
                    <div class="uc-level-info">
                        <i class="ri-vip-diamond-line ri-lg ri-top"></i>
                        <span class="level">会员等级</span>
                        <span class="desc">会员超级权益，享受订单8.5折</span>
                    </div>
                </div>

                <!-- 订单展示 -->
                <div class="uc-order" @click="active='2'">
                    <div class="uc-title">
                        <span>我的订单</span>
                        <div>查看所有订单 <i class="ri-arrow-right-s-line ri-lg ri-top"></i> </div>
                    </div>
                    <div class="uc-order-ul">
                        <div class="ucou-li" v-for="(item,index) in list.order" :key="index">
                            <i class="ri-xl" :class="item.icon" :style="{color:item.iconColor}"></i>
                            <div>{{item.name}}</div>
                        </div>
                    </div>
                </div>

                <div class="uc-banner" v-if="list.advert.show" @click="active='1'">
                    <img :src="list.advert.cover" alt="">
                </div>

                <!-- 常用功能 -->
                <div class="uc-tool" v-for="(val,ind) in list.tool" :key="ind" @click="active='3'">
                    <div class="uc-title">
                        <span>{{val.name}}</span>
                    </div>
                    <a-tooltip title="可拖动排序">
                        <div class="uc-tool-ul">
                            <draggable v-model="val.list" item-key="uniid" animation="500" >
                                <template #item="{element}">
                                    <div class="uc-tool-ul-li">
                                        <i class="ri-xl" :class="element.icon" :style="{color:element.iconColor}"></i>
                                        <div>{{element.name}}</div>
                                    </div>
                                </template>
                            </draggable>
                        </div>
                    </a-tooltip>
                </div>

                <div style="width:100%;height:20px;"></div> 
            </div>

            <div class="uc-config">
                <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
                    <a-tabs v-model:activeKey="active" tab-position="right">
                        <a-tab-pane key="1" tab="基本信息">
                            <a-form-item label="标题">
                                <a-input v-model:value="list.title"></a-input>
                            </a-form-item>
                            <a-form-item label="背景颜色">
                                <kd-color :fixd="true" v-model="list.bannerBg"></kd-color>
                            </a-form-item>
                            <a-form-item label="字体颜色">
                                <kd-color :fixd="true" v-model="list.bannerFontColor"></kd-color>
                            </a-form-item>
                            <a-form-item label="展示广告">
                                <a-switch v-model:checked="list.advert.show"></a-switch>
                            </a-form-item>
                            <div v-if="list.advert.show">
                                <a-form-item label="广告图片">
                                    <kd-img-select :src="list.advert.cover" @change="e=>list.advert.cover=e"></kd-img-select>
                                </a-form-item>
                                <a-form-item label="跳转链接">
                                    <com-select-link v-model="list.advert.path" @changeJump="e=>list.advert.jump = e"></com-select-link>
                                    <div v-if="list.advert.jump == 2">
                                        <a-input v-model:value="list.advert.appid" placeholder="小程序appid"></a-input>
                                        <a-input v-model:value="list.advert.mini_path" placeholder="小程序打开页面"></a-input>
                                    </div>
                                    <span v-if="list.advert.jump == 3">
                                        <a-input v-model:value="list.advert.path" placeholder="电话号码"></a-input>
                                    </span>
                                </a-form-item>
                            </div> 
                            <a-form-item :wrapper-col="{offset:4}">
                                <a-button type="primary" @click="saveUserCenter">保存</a-button>
                            </a-form-item>
                        </a-tab-pane>
                        <a-tab-pane key="2" tab="订单信息">
                            <div class="ucc-order-li" v-for="(item,index) in list.order" :key="index">
                                <a-form-item label="名称">
                                    <a-input v-model:value="item.name"></a-input>
                                </a-form-item>
                                <a-form-item label="图标">
									<com-reminxi-icon :value="item.icon" @changeValue="(e)=>{item.icon = e}"></com-reminxi-icon>
		                        </a-form-item>
                                <a-form-item label="颜色">
                                    <kd-color v-model="item.iconColor"></kd-color>
                                </a-form-item>
                            </div>
                            <a-form-item :wrapper-col="{offset:4}">
                                <a-button type="primary" @click="saveUserCenter">保存</a-button>
                            </a-form-item>
                            <div style="width:100%;height:200px;"></div>
                        </a-tab-pane>
                        <a-tab-pane key="3" tab="必备工具">
                            <a-tabs v-model:activeKey="toolActive" tab-position="top" type="editable-card" @edit="addToolsGroup">
                                <a-tab-pane v-for="(val,ind) in list.tool" :key="ind" :tab="val.name" :closable="true">
                                    <a-form-item label="名称">
                                        <a-input v-model:value="val.name"></a-input>
                                    </a-form-item>
                                    <div class="ucc-order-li" v-for="(item,index) in val.list" :key="index">
                                        <a-form-item label="是否展示">
                                            <span>APP：</span><a-switch v-model:checked="item.app_show"></a-switch>
                                            <span style="margin:0 12px"></span>
                                            <span>小程序：</span><a-switch v-model:checked="item.wxapp_show"></a-switch>
                                        </a-form-item>
                                        <a-form-item label="颜色">
                                            <kd-color :fixd="false" v-model="item.iconColor"></kd-color>
                                        </a-form-item>
                                        <a-form-item label="图标">
                                            <com-reminxi-icon :value="item.icon" @changeValue="(e)=>{item.icon = e}"></com-reminxi-icon>
                                        </a-form-item>
                                        <a-form-item label="名称">
                                            <a-input v-model:value="item.name"></a-input>
                                        </a-form-item>
                                        <div class="ucc-delete" @click="deleteTools(index)">删除</div>
                                    </div>
                                    <div class="icc-add" @click="checkSelect"><i class="ri-add-line ri-top"></i>新增</div>
                                    <a-form-item :wrapper-col="{offset:4}">
                                        <a-button type="primary" @click="saveUserCenter">保存</a-button>
                                    </a-form-item>
                                    <div style="width:100%;height:180px;"></div>
                                </a-tab-pane>
                            </a-tabs>
                            
                           
                        </a-tab-pane>
                    </a-tabs>
                </a-form>
            </div>
        </div>

        <a-modal v-model:visible="show" title="添加" @ok="surePush" width="800px">
            <span class="f12">注意：灰色背景表示为已添加到工具中！</span>    
            <div class="model-tools">
                <div 
                    class="model-tools-item" 
                    v-for="(item,index) in allTool" 
                    :key="index"
                    :style="{background:item.checked ? '#f4f4f4':'#fff'}" 
                    @click="addPushTool(index)"
                >
                    <i class="ri-xl" :class="item.icon" :style="{color:item.iconColor}"></i>
                    <div>{{item.name}}</div>
                    <img class="select-img" v-if="item.selected" src="@/assets/img/icon-share-selected.png" alt="">
                </div>
            </div>
        </a-modal>
    </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import customPageModel from '@/api/customPage'
import comReminxiIcon from '@/components/public/com-reminxi-icon.vue'
import draggable from 'vuedraggable'
import setModel from '@/api/set'
import comSelectLink from '@/components/miniapp/com-select-link.vue'
export default {
    name:'custom-user-center',
    components:{
        comReminxiIcon,
        draggable,
        comSelectLink
    },
    setup() {
        const state = reactive({
            active:'1',
            toolActive:0,
            show:false,
            list:null,
            allTool:customPageModel.getUcTool(),
            beforehandPush:[],  //预追加
        })

        ;(function(){
            setModel.getFarmSet(['user_center_page']).then(res=>{
                if( res.user_center_page ){
                    state.list = JSON.parse(res.user_center_page)
                }else{
                    //默认
                    state.list = {
                        title:"个人中心",
                        bannerBg:'#00af57',
                        bannerFontColor:'#ffffff',
                        advert:{
                            show:false,
                            cover:"",
                            path:"",
                            jump:1,
                            appid:'', 
                            mini_path:''
                        },
                        order:[
                            {name:'待支付',icon:'ri-wallet-3-fill',iconColor:"#00af57",uniid:'order_stay_pay'},
                            {name:'待发货',icon:'ri-inbox-unarchive-fill',iconColor:"#00af57",uniid:'order_stay_delivery'},
                            {name:'待收货',icon:'ri-truck-fill',iconColor:"#00af57",uniid:'order_stay_confirm'},
                            {name:'已完成',icon:'ri-message-2-fill',iconColor:"#00af57",uniid:'order_complete'},
                            {name:'售后',icon:'ri-exchange-cny-fill',iconColor:"#00af57",uniid:'order_sale'},
                        ],
                        tool:[
                            {name:'必备工具',list:customPageModel.getUcTool() }
                        ],
                    }
                }
            })
        })();

        function checkSelect(){
            let keys = []
            state.list.tool.forEach(item=>{
                item.list.forEach(val=>keys.push(val.uniid))
            })
            state.allTool.forEach(item=> {
                item.checked = keys.includes(item.uniid) ? true:false
                item.selected = false
            })
            state.show = true
        }
        const deleteTools = index=>state.list.tool[state.toolActive].list.splice(index,1)
        const addPushTool = index=>{
            if( ! state.allTool[index].checked ) {
                state.beforehandPush.push(state.allTool[index])
                state.allTool[index].selected = true
            }
        }

        function surePush(){
            if( state.beforehandPush.length > 0 ){
                state.beforehandPush.forEach(item=>state.list.tool[state.toolActive].list.push(item))
            }
            state.show = false
            state.beforehandPush = []
        }

        function saveUserCenter(){
            let data = {
                user_center_page:JSON.stringify(state.list)
            }
            setModel.setFarmSet(data)
        }

        //添加功能组
        function addToolsGroup(e,action){
            if( action == 'add' ){
                state.list.tool.push({name:'功能组',list:[]})
            }else{
                state.list.tool.splice(e,1)
            }
        }

        return{
            ...toRefs(state),
            deleteTools,
            checkSelect,
            addPushTool,
            surePush,
            saveUserCenter,
            addToolsGroup
        }
    },
}
</script>
<style lang="scss" scoped>
.uc-con{
    display: flex;
}
.uc-phone::-webkit-scrollbar {
    display: none; /* Chrome Safari */
}
.uc-phone{
    width: 375px;
    height: 667px;
    box-sizing: border-box;
    background: #f4f4f4;
    overflow: hidden;
    overflow-y: auto;
    cursor: pointer;

    &-title{
        width: 100%;
        height: 44px;
        line-height: 44px;
        text-align: center;
        background: #00af57;
        font-size: 18px;
    }

    .uc-title{
        display: flex;
        justify-content: space-between;
        height: 48px;
        align-items: center;
        padding: 0 12px;

        >span{
            font-size: 15px;
            font-weight: bold;
        }
        >div{
            color: #999;
            font-size: 12px;
        }
        
    }

    &-header{
		width: 100%;  
		height: 170px;  
		z-index: -1;  
		overflow: hidden;
		padding: 12px;
		background: #00af57;
		
		.uc-user{
			width: 100%;
			display: flex;
			align-items: center;
			padding-top: 12px;
		}
		.phone{
			font-size: 17px;
            font-weight: bold;
		}
		.avatar{
            width: 60px;
            height: 60px;
            border-radius: 50%;
			margin-right: 12px;
		}
		
		.uc-set{
            margin-left: 307px;
            margin-top: -56px;
            font-size: 22px;
			span{
				margin-right: 6px;
			}
		}
		.uc-nums{
			display: flex;
			justify-content: space-between;
			margin-top: 32px;
			
			&-item{
				width: 30%;
				height: 60px;
				background: rgba($color: #FFFFFF, $alpha: .15);
				text-align: center;
				padding-top: 12px;
			}
			.main{
				text{
					font-size: 16px;
                    font-weight: bold;
				}
			}
			
			.desc{
				font-size: 12px;
				letter-spacing: 2px;
			}
		}
		
		.uc-level{
			width: 100%;
			height: 64px;
			background: #000000;
			border-radius: 8px;
			margin-top: 12px;
		}
	}

    .uc-level{
        width: 100%;
        height: 60px;
        border-radius: 0 0 50% 50%;
		background: #00af57;
		margin-top: -5px;
		padding: 12px;
		overflow: hidden;
		&-info{
            width: 100%;
            height: 50px;
            border-radius: 8px;
			background: #000;
			color: #e8bd1f;
			padding: 12px;
			text-align:center;
			
			.level{
                font-size: 16px;
                font-weight: bold;
				margin: 0 6px;
			}
			.desc{
				font-size: 12px;
			}
			
		}
	}

    .uc-order{
        margin-top: 12px;
        background: #fff;
        width:94%;
        margin-left: 3%;
        border-radius: 6px;
        padding: 12px 0;

        &-ul{
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
        .ucou-li{
            width: 20%;
            text-align: center;

            >div{
                color: #666;
            }
        }
    }

    .uc-tool{
        margin-top: 12px;
        background: #fff;
        width:94%;
        margin-left: 3%;
        border-radius: 6px;

        &-ul{
            width: 100%;
            >div{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
            }
            

            &-li{
                width: 25%;
                text-align: center;
                margin-bottom: 24px;

                >div{
                    color: #666;
                }
            }
        }
    }
}

.uc-config{
    width: 700px;
    height: 667px;
    border: 1px solid #f4f4f4;
    margin-left: 24px;
    box-sizing: border-box;
    padding-top: 24px;
    overflow: hidden;
    overflow-y: auto;

    .ucc-order-li{
        width: 90%;
        border: 1px solid #f4f4f4;
        margin-bottom: 12px;
        position: relative;
        cursor: pointer;
        margin-left: 5%;
    }
    .ucc-delete{
        position: absolute;
        background: #dedede;
        font-size: 12px;
        right: 0;
        margin-top: -18px;
        padding: 0 6px;
        cursor: pointer;
        display: none;
    }

    .ucc-order-li:hover .ucc-delete{
        display: block;
    }
    .icc-add{
        width: 90%;
        border: 1px dashed #999;
        color: #999;
        height: 40px;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
        margin-left: 5%;
    }
}

.model-tools{
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    &-item{
        width: 110px;
        text-align: center;
        margin: 0 6px 6px 0;
        border: 1px solid #f4f4f4;
        padding: 12px;
        cursor: pointer;
        position: relative;
    }
    .select-img{
        position: absolute;
        width: 20px;
        height: 20px;
        right: 0;
        margin-top: -8px;
    }
}

.uc-banner{
    width: 94%;
    margin: 12px 3%;

    img{
        width: 100%;
    }
}
</style>